<template>
  <div>
    <b-card title="Facturas">
      <b-card-text>
        <b-row>
          <b-col cols="12" md="6">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                v-if="$hascan('factura-registrar')"
                @click="$router.push('/facturas/nueva-factura')"
              >
                <feather-icon icon="SaveIcon" class="mr-50" />
                <span class="align-middle">NUEVA FACTURA</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="$hascan('factura-ver')"
                @click="verFactura(item)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span class="align-middle">VER</span>
                </span>
                <feather-icon icon="EyeIcon" class="d-md-none" size="14" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="$hascan('factura-anular')"
                @click="confirmarEliminar(item)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="XIcon" class="mr-50" />
                  <span class="align-middle">ANULAR</span>
                </span>
                <feather-icon icon="XIcon" class="d-md-none" size="14" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="$hascan('factura-verificar')"
                @click="confirmarVerificarFactura(item)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="CheckSquareIcon" class="mr-50" />
                  <span class="align-middle">VERIFICAR</span>
                </span>
                <feather-icon
                  icon="CheckSquareIcon"
                  class="d-md-none"
                  size="14"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="listar"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                  <span class="align-middle">RECARGAR</span>
                </span>
                <feather-icon
                  icon="RefreshCcwIcon"
                  class="d-md-none"
                  size="14"
                />
              </b-button>
            </div>
          </b-col>
          <b-col cols="12" md="6" class="mt-1 mt-md-0">
            <b-row style="justify-content: flex-end">
              <b-col cols="4" lg="2" md="6">
                <b-form-group
                  class="mb-0"
                  label=""
                  label-align-sm="right"
                  label-for="filterInput"
                >
                  <flat-pickr
                    v-model="mesAnio"
                    class="form-control form-control-sm-small"
                    placeholder="SELECCIONE UN RANGO DE FECHAS"
                    :config="config"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="8" lg="6" md="6">
                <b-form-group
                  class="mb-0"
                  label=""
                  label-align-sm="right"
                  label-for="filterInput"
                >
                  <b-form-input
                    v-model="searchQuery"
                    :formatter="textFormatter"
                    @input="updateSearchQuery"
                    type="search"
                    size="sm"
                    placeholder="Buscar.."
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text>
        <Listado
          ref="listadoRef"
          :items="items"
          :loading="loading"
          @selectItemFactura="selectItemFactura"
        />
      </b-card-text>
      <b-card-text>
        <b-row>
          <b-col cols="6" md="6">
            <span class="text-nowrap text-bold">
              <strong>
                Mostrando {{ from }} - {{ to }} de {{ total }} Registros
              </strong>
            </span>
          </b-col>
          <b-col cols="6" md="6">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @change="handlePageChange"
              align="right"
              size="sm"
              class="my-0"
            >
              <template #prev-text>
                <feather-icon size="18" icon="ChevronLeftIcon" />
              </template>
              <template #next-text>
                <feather-icon size="18" icon="ChevronRightIcon" />
              </template>
              <template #last-text>
                <feather-icon size="18" icon="ChevronsRightIcon" />
              </template>
              <template #first-text>
                <feather-icon size="18" icon="ChevronsLeftIcon" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-card-text>
      <!-- MODAL DE ANULACION -->
      <b-modal
        v-model="anularFacturaModal"
        title="ANULAR FACTURA?"
        ok-only
        ok-title="Anular"
        @cancel.prevent="cerrarModalAnular"
        @close.prevent="cerrarModalAnular"
        @ok.prevent="anularFactura"
        :ok-disabled="!motivoAnulacion || submitted"
        :cancel-disabled="submitted"
      >
        <b-overlay
          :show="submitted"
          no-wrap
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        />
        <b-card-text>
          <b-form-radio-group
            v-model="motivoAnulacion"
            :options="motivoAnulacionItems"
            value-field="codigo"
            text-field="descripcion"
            class="demo-inline-spacing"
            name="radio-inline"
            @input="obtenerMotivo"
          />
        </b-card-text>
      </b-modal>
      <ModalPdf
        :pdf="pdf"
        :urlSiat="urlSiat"
        :facturaId="facturaId"
        :pdfBase64="pdfBase64"
        :accionModalPdf="accionModalPdf"
        @cerrarModalPdf="cerrarModalPdf"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BCol,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BInputGroupAppend,
  BInputGroup,
  BFormRadioGroup,
  BModal,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Listado from "../views/Listado.vue";
import flatPickr from "vue-flatpickr-component";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import vSelect from "vue-select";
import FacturacionServices from "@/modules/configuracion-sin/services/index";
import ModalPdf from "@/modules/components/ModalPdf.vue";
import "flatpickr/dist/plugins/monthSelect/style.css";
import moment from "moment";
import VentaServices from "@/modules/venta/services/index";
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    BPagination,
    Listado,
    BRow,
    BCol,
    BOverlay,
    BDropdown,
    BDropdownItem,
    vSelect,
    flatPickr,
    BInputGroupAppend,
    BInputGroup,
    BFormRadioGroup,
    BModal,
    ModalPdf,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      anularFacturaModal: false,
      loading: false,
      items: [],
      item: [],
      buttonDisabled: true,
      searchQuery: "",

      // filtros
      mesAnio: "",

      totalRows: 1,
      currentPage: 1,
      page: 0,
      perPage: 1,
      from: 0,
      to: 0,
      total: 0,
      size: 25,
      mes: null,
      anio: null,

      clienteItems: [],
      cliente_id: null,
      config: {
        locale: Spanish,
        dateFormat: "Y-m",
        plugins: [
          new monthSelectPlugin({
            shorthand: true, //defaults to false
            dateFormat: "Y-m", //defaults to "F Y"
            altFormat: "Y-m", //defaults to "F Y"
          }),
        ],
      },
      fecha: null,

      // facturacion
      documentoSector: 1,
      puntoVenta: 0,
      numeroSucursal: 0,
      // end facturacion

      motivoAnulacionItems: [],
      motivoAnulacion: null,

      // pdf
      accionModalPdf: false,
      pdf: "",
      urlSiat: "",
      pdfBase64: "",
      facturaId: "",
      submitted: false,
      anular: [],
      debounce: null,
    };
  },
  created() {},
  watch: {
    cliente_id: function () {
      this.refetchData();
    },
    mesAnio: function () {
      this.refetchData();
    },
  },
  methods: {
    async listar() {
      this.loading = true;
      try {
        const params = {
          page: this.page,
          size: this.size,
          busqueda: this.searchQuery,
          mes: moment(this.mesAnio).format("MM"),
          anio: moment(this.mesAnio).format("YYYY"),
        };
        const response = await FacturacionServices.get(
          this.numeroSucursal,
          this.puntoVenta,
          this.documentoSector,
          params
        );
        this.items = response.data.data.content;
        this.totalRows = response.data.data.totalPages;
        this.total = response.data.data.totalElements;
        this.from = response.data.data.number * response.data.data.size + 1;
        this.to =
          response.data.data.number * response.data.data.size +
          response.data.data.numberOfElements;

        this.loading = false;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast({
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
      this.buttonDisabled = true;
    },
    refetchData() {
      if (this.currentPage != 1) {
        this.currentPage = 1;
        this.page = this.currentPage - 1;
      }
      this.listar();
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.page = value - 1;
      this.listar();
    },
    selectItemFactura(item, bool) {
      this.item = item;
      this.buttonDisabled = bool;
    },
    updateSearchQuery() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (this.currentPage != 1) {
          this.currentPage = 1;
          this.page = 0;
        } else {
          this.listar();
        }
      }, 300);
      // this.$refs.listadoRef.updateSearchQuery(val);
    },
    confirmarEliminar(item) {
      this.anularFacturaModal = true;
    },
    confirmarVerificarFactura(item) {
      this.$bvModal
        .msgBoxConfirm("Confirme que desea verificar la factura.", {
          title: "VERIFICAR FACTURA?",
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "Si, Verificar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-dark",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.verificarFactura(item.id);
          }
        });
    },
    async verificarFactura(id) {
      this.loading = true;
      try {
        const response = await FacturacionServices.verificar(id);
        let mensaje = response.data.message;
        this.$bvToast.toast(mensaje, {
          title: "EXITO!",
          variant: "success",
          solid: false,
        });
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast({
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
        this.loading = false;
      }
    },

    // filtros
    onChange(selectedDates, dateStr, instance) {
      if (selectedDates[0] && selectedDates[1]) {
        let dateStart = instance.formatDate(selectedDates[0], "Y-m-d");
        let dateEnd = instance.formatDate(selectedDates[1], "Y-m-d");
        this.fecha = { dateStart, dateEnd };
      }
    },
    onClose(selectedDates, dateStr, instance) {
      if (selectedDates[0] && selectedDates[1]) {
      } else {
        this.fecha = null;
      }
    },
    // search cliente
    onSearchCliente(search, loading) {
      let params = {
        buscar: search,
      };
      if (search.length) {
        loading(true);
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          loading(false);
        }, 300);
      } else {
        loading(false);
        this.clienteItems = [];
      }
    },
    getCliente() {
      if (this.cliente_id == null) {
        this.cliente_id = null;
      } else {
        this.cliente_id = this.cliente_id.id;
      }
    },
    async getMotivoAnulacion() {
      try {
        const response = await FacturacionServices.getCatalogos(3);
        this.motivoAnulacionItems = response.data.data;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast({
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    async verFactura(item) {
      this.pdfBase64 = item.camposEspecificos.pdf;
      this.pdf =
        "data:application/pdf;base64," +
        item.camposEspecificos.pdf +
        "#toolbar=0";
      this.urlSiat = item.urlSiat;
      this.facturaId = item.id;
      this.accionModalPdf = true;
    },

    cerrarModalAnular() {
      this.anularFacturaModal = false;
      this.motivoAnulacion = null;
      this.anular = [];
    },
    async anularFactura() {
      this.submitted = true;
      try {
        await FacturacionServices.anular(this.anular);
        this.anularFacturaModal = false;
        this.$bvToast.toast("La Factura fue Anulada Existosamente", {
          title: "EXITO!",
          variant: "success",
          solid: false,
        });
        if (this.item.documentoOrigen) {
          await this.actualizar(parseInt(this.item.documentoOrigen), {
            factura_id: null,
            numero_factura: null,
          });
        }
        await this.listar();
        this.submitted = false;
        this.motivoAnulacion = null;
      } catch (err) {
        this.submitted = false;
        this.anularFacturaModal = true;
        this.motivoAnulacion = null;
        const errorMessage =
          err.response.data.data.codigoDescripcion +
          ": " +
          err.response.data.data.mensajesList[0].descripcion;
        this.$bvToast.toast(errorMessage, {
          title: "ERROR!",
          autoHideDelay: 7000,
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar(id, data) {
      try {
        await VentaServices.update(id, data);
      } catch (err) {
        await this.listar();
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    obtenerMotivo() {
      if (this.motivoAnulacion) {
        let anular = this.motivoAnulacionItems.find(
          (x) => x.codigo === this.motivoAnulacion
        );
        this.anular = {
          codigoMotivo: anular.codigo,
          descripcionMotivo: anular.descripcion,
          idFactura: this.item.id,
        };
      }
    },
    cerrarModalPdf() {
      this.accionModalPdf = false;
      this.pdf = "";
      this.urlSiat = "";
      this.pdfBase64 = "";
      this.facturaId = "";
    },
  },
  mounted() {
    const fechaActual = moment();
    this.mesAnio = fechaActual.format("YYYY-MM");
    this.listar();
    this.getMotivoAnulacion();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
</style>
