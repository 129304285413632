<template>
  <div>
    <b-overlay :show="loading">
      <ag-grid-vue
        :gridOptions="gridOptions"
        class="ag-theme-balham ag-grid-table mb-1"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
        :rowData="items"
        rowSelection="single"
        colResizeDefault="shift"
        :animateRows="false"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowClassRules="rowClassRules"
        @selection-changed="onSelectionChanged"
        @first-data-rendered="onFirstDataRendered"
        :alwaysShowVerticalScroll="true"
        :suppressPaginationPanel="true"
      >
      </ag-grid-vue>
    </b-overlay>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import {
  BCard,
  BCardText,
  BOverlay,
  BPagination,
  BRow,
  BCol,
} from "bootstrap-vue";
import MoodEditor from "./moodEditorVue.js";
export default {
  components: {
    BCard,
    BCardText,
    AgGridVue,
    MoodEditor,
    BOverlay,
    BPagination,
    BRow,
    BCol,
  },
  props: ["items", "loading"],
  data() {
    return {
      // grid
      gridOptions: {
        localeText: {
          noRowsToShow: "No hay filas para mostrar",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,

      gridApi: null,
      selectedRows: [],
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "NRO FACTURA",
          field: "nroFactura",
          filter: false,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: false,
          minWidth: 120,
          maxWidth: 120,
        },
        {
          headerName: "NRO DOC. ORIGEN",
          field: "documentoOrigen",
          filter: false,
          minWidth: 140,
          maxWidth: 140,
        },
        {
          headerName: "FECHA DE EMISION",
          field: "fechaEmision",
          filter: false,
          minWidth: 160,
          maxWidth: 160,
        },
        {
          headerName: "CUF",
          field: "cuf",
          filter: false,
          minWidth: 220,
        },
        {
          headerName: "DOCUMENTO",
          field: "nroDocumentoIdentidad",
          filter: false,
          minWidth: 220,
          maxWidth: 220,
        },
        {
          headerName: "RAZON SOCIAL",
          field: "nombreRazonSocial",
          filter: false,
          minWidth: 300,
          maxWidth: 300,
        },
        // {
        //     headerName: 'SUBTOTAL',
        //     field: 'subtotal',
        //     type: "numericColumn",
        //     filter: false,
        //     minWidth: 120, maxWidth: 120,
        // },
        {
          headerName: "DESCUENTO",
          field: "camposEspecificos.descuentoAdicional",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: false,
          minWidth: 120,
          maxWidth: 120,
        },
        {
          headerName: "TOTAL",
          field: "montoTotal",
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: false,
          minWidth: 120,
          maxWidth: 120,
        },
        {
          headerName: "USUARIO",
          field: "usuario",
          filter: false,
          minWidth: 120,
          maxWidth: 120,
        },
        {
          headerName: "ESTADO",
          field: "estado",
          filter: false,
          // valueGetter: function(param){
          //   if(param.data.estado==0){
          //       return 'ANULADO';
          //   }else{
          //       return 'VALIDADA';
          //   }
          // },
          minWidth: 200,
          maxWidth: 200,
        },
      ],
      rowClassRules: { "row-red": "data.estado === 0" },
      searchQuery: "",
    };
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 25;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  created() {},
  methods: {
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onSelectionChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      this.selectedRows = selectedRows;
      if (this.selectedRows != "") {
        this.$emit("selectItemFactura", this.selectedRows[0], false);
      } else {
        this.selectedRows = [];
        this.$emit("selectItemFactura", this.selectedRows, true);
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
function numberFormatter(params) {
  return Number(params.value).toFixed(2);
}
</script>

<style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
.ag-theme-balham .row-red {
  color: #ea5455 !important;
}
</style>
